// @flow
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Link } from 'gatsby';
import { Box, Typography, Button } from '@material-ui/core';
import sortBy from 'lodash.sortby';
import { Layout } from '../../components';
import { fetchAgreementDocuments } from '../../redux/agreement-document';
import styles from '../../styles/pages/agreement_documents.module.scss';
import { getOgData } from '../../utils';

const AgreementDocumentsPage = ({ docs, _fetchAgreementDocuments }) => {
  useEffect(() => {
    _fetchAgreementDocuments();
  }, []);

  const sortedDocs = sortBy(docs || [], 'order');

  return (
    <Layout hasNoHeader hasNoFooter>
      <Helmet
        meta={getOgData()}
        script={[
          {
            async: 'async',
            defer: 'defer',
            innerHTML: `
            (function(d){
              var l = d.createElement("link");
              var s = d.getElementsByTagName("script")[0];
              l.rel = "stylesheet";
              l.href = "https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese";
              s.parentNode.insertBefore(l, s);
            })(document);
            `,
          },
        ]}
      />
      <Box className={styles.heroWrapper}>
        <img
          alt="Paidy logo"
          src={require('../../images/logo-color.svg')}
          height="auto"
          width="139"
          loading="lazy"
        />
        <Typography variant="h1">加盟店契約に関する文書</Typography>
      </Box>
      <Box className={styles.content}>
        <div className={styles.container}>
          <p>
            加盟店の皆様。加盟店契約に関する文書を以下に掲載しております。最新の内容をご確認くださいますようお願い申し上げます。
          </p>
        </div>
      </Box>
      <Box className={cx(styles.content, styles.gray)}>
        <div className={styles.container}>
          <ul>
            {sortedDocs.map((doc, index) => (
              <li key={`agreement-document-${index}`}>
                ・{' '}
                <a rel="noreferrer" target="_blank" href={doc.url}>
                  {doc.japaneseDocumentName}
                </a>
                （{(doc.type || '').toUpperCase()}）
              </li>
            ))}
          </ul>
        </div>
      </Box>
      <Box className={styles.content}>
        <div className={styles.container}>
          <p>
            ※当社は、本規約等を変更した場合に、当該変更内容および新規約等を加盟店様に通知いたします。加盟店様がその通知を受けた後に、ユーザーに対して信用販売を行った場合には、変更事項又は新規約等を承認したものとしてお取扱いいたします。
            <br />
            <br />
            ご不明点やご不安な点がございましたら、お気軽にお問合せください。
          </p>
          <Button
            variant="contained"
            color="primary"
            to="/merchant/contact_us/"
            component={Link}
            className={styles.button}
          >
            ご相談はこちらから
          </Button>
        </div>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  docs: state.agreementDocument.data,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchAgreementDocuments: () => dispatch(fetchAgreementDocuments()),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementDocumentsPage);
